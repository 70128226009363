@import '~fonts/fontawesome-pro-6.6.0-web/css/all';

.nobr {
  white-space: nowrap;
}


.syllabus {
  text-align: left;

  .list-group-item {
    p {
      padding-top: 0px;
      padding-bottom: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}

.calendly-inline-widget {
  min-width: 320px;
  height: 650px;
  margin-bottom: 100px
}

.gmb-categories-table th:last-of-type {
  min-width: 150px;
}

.black-friday {
  display: block;
  background-color: #011528;

  @media screen and (min-width: 992px) {
    background-color: #377dff;
  }

  &-container {
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin: 0 auto;
    max-width: 62.5rem;
  }

  &-content {
    display: flex;
    flex-direction: column;
    background-color: #011528;
    padding: .5rem .75rem;
    color: #ffffff;
    font-size: .75rem;
    line-height: 1.2;

    @media screen and (min-width: 992px) {
      font-size: .67rem;
    }

    span {
      color: #f9ae34;
    }
  }
}
